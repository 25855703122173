



































import Vue from 'vue';

export default Vue.extend({
    data() {
        return {}
    },
    props: {
        'toolData': Object
    },
    computed: {
        // Generates the image URL
        imageUrl: function() {
            const urlBase = 'images/';

            return urlBase + this.toolData.image;
        }
    }
});
